import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Toast } from 'app/shared/models/Toast';
import { ToastService } from 'app/chat/services/toast.service';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
})
export class ToastComponent implements OnInit {
  toast!: Toast;
  show: boolean = false;

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.toastService.toastState$.subscribe((toast) => {
      this.toast = toast;
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, this.toast.duration || 3000);
    });
  }

  close() {
    this.show = false;
  }
}
