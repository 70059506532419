export type Document = {
  documentName: string;
  expireAt: string;
  id: string;
  model: string;
  path: string;
  type: string;
};

export enum DocType {
  PDF = 'application/pdf',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  PPT = 'application/vnd.ms-powerpoint',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  TXT = 'text/plain',
  HTML = 'text/html',
}
