import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toast } from '../../shared/models/Toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<Toast>();

  toastState$ = this.toastSubject.asObservable();

  showToast(toast: Toast) {
    this.toastSubject.next(toast);
  }
}
