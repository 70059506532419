import {
  DestroyRef,
  Injectable,
  Injector,
  WritableSignal,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { ToastService } from './toast.service';
import { Toast } from '../../shared/models/Toast';
import { DocType, Document } from '../../shared/models/Document';
import { environment } from 'environments/environment';
import { StorageService } from 'app/shared/services/storage.service';
import { MessageData, SseService } from './sse.service';
import { HttpClient } from '@angular/common/http';
import { Message } from 'app/shared/models/Message';
import { firstValueFrom, map, Observable, take } from 'rxjs';

type Event =
  | ErrorEvent
  | CompletionsEvent
  | EmbeddingEvent
  | CompletedEvent
  | StatusEvent;

type ErrorEvent = {
  event: 'error';
  error: string;
};

type CompletionsEvent = {
  event: 'completions';
  chunk: string;
};

type EmbeddingEvent = {
  event: 'embedding';
};

type CompletedEvent = {
  event: 'completed';
  contextId: string;
  metadata: any;
};

type StatusEvent = {
  event: 'status';
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private sseService = inject(SseService);
  private toastService = inject(ToastService);
  private sessionStorageService: StorageService = inject(StorageService);
  private httpClient = inject(HttpClient);
  private _progressMessageInProgress: {
    [key: string]: WritableSignal<string>;
  } = {};
  private _statusMessage: {
    [key: string]: WritableSignal<string | null>;
  } = {};
  private _loadingInProgress: {
    [key: string]: WritableSignal<boolean>;
  } = {};
  private _responses: {
    [key: string]: WritableSignal<Message[]>;
  } = {};
  private _uploadFileLoading: {
    [key: string]: WritableSignal<boolean>;
  } = {};

  constructor() {
    this.sse
      .pipe(
        map((message: MessageData<Event>) => {
          const progressMessage =
            this._progressMessageInProgress[message.conversation];
          const loading = this._loadingInProgress[message.conversation];
          const responses = this._responses[message.conversation];
          const status = this._statusMessage[message.conversation];
          const uploadFileLoading =
            this._uploadFileLoading[message.conversation];
          const res = message.message;
          if (res.event === 'completions')
            progressMessage?.update((msg) => msg + res.chunk);
          if (res.event === 'status') {
            status?.update(() => res.message);
          }
          if (res.event === 'embedding') {
            uploadFileLoading.set(false);
            const toast: Toast = {
              message: 'Fichier téléchargé avec succès.',
              type: 'success',
            };
            this.toastService.showToast(toast);
          }
          if (res.event === 'error') {
            if (res.error.toLowerCase().includes('abort'))
              this.toastService.showToast({
                type: 'info',
                message: 'Votre recherche a été annulée.',
              });
            else
              this.toastService.showToast({
                type: 'error',
                message: res.error,
              });
            uploadFileLoading?.set(false);
          }
          if (res.event === 'completed') {
            status?.set(null);
            responses?.update((messages) => [
              ...messages,
              {
                content: progressMessage(),
                type: 'ai',
                createdAt: new Date().toISOString(),
                author: 'PrivateGPT',
                contextId: res.contextId,
                metadata: res.metadata,
              },
            ]);
            loading?.set(false);
            progressMessage.set('');
          }
        })
      )
      .subscribe();
  }

  get sse(): Observable<MessageData<Event>> {
    return this.sseService.getSSE();
  }

  public getSelectedModel(): string {
    return this.sessionStorageService.getFromSessionStorage('selected-model');
  }

  public setSelectedModel(selectedModel: string) {
    return this.sessionStorageService.storeInSessionStorage(
      'selected-model',
      selectedModel
    );
  }

  public getProgressMessageInProgress(
    key?: string
  ): undefined | WritableSignal<string> {
    if (!key) return;
    return this._progressMessageInProgress[key] ?? undefined;
  }

  public getStatusMessage(key?: string): WritableSignal<string | null> | null {
    if (!key) return null;
    return this._statusMessage[key] ?? null;
  }

  public getLoadingInProgress(
    key?: string
  ): undefined | WritableSignal<boolean> {
    if (!key) return;
    return this._loadingInProgress[key] ?? undefined;
  }

  public getResponses(key?: string): undefined | WritableSignal<Message[]> {
    if (!key) return;
    return this._responses[key] ?? undefined;
  }

  public sendPrompt(
    prompt: string,
    conversationId: string,
    selectedVersionGPT: string,
    contextId: string | null
  ): Promise<any> {
    return firstValueFrom(
      this.httpClient.post(
        `${environment.apiConfig.uri}/chat`,
        {
          prompt,
          conversationId,
          modelName: selectedVersionGPT,
          contextId,
          langCode: navigator.language,
        },
        { withCredentials: true }
      )
    );
  }

  public abortCompletion(conversationId: string): void {
    this.httpClient
      .post(
        `${environment.apiConfig.uri}/chat/abort`,
        { conversationId },
        { withCredentials: true }
      )
      .pipe(take(1))
      .subscribe();
  }

  public sendFiles(files: FileList, conversationId: string): void {
    if (files.length > 0) {
      const formData: FormData = new FormData();

      for (var x = 0; x < files.length; x++) {
        formData.append('files', files[x]);
      }
      formData.append(
        'data',
        JSON.stringify({
          conversationId,
          model: this.getSelectedModel(),
        })
      );
      this.httpClient
        .post(`${environment.apiConfig.uri}/chat/embedding`, formData, {
          withCredentials: true,
        })
        .pipe(take(1))
        .subscribe();
    }
  }

  public listenEmbedding(
    uploadFileLoading: WritableSignal<boolean>,
    conversationId: string,
    documents: WritableSignal<Document[]>
  ): void {
    uploadFileLoading.set(true);
  }

  public listenProgressMessage(
    progressMessage: WritableSignal<string>,
    loading: WritableSignal<boolean>,
    uploadFileLoading: WritableSignal<boolean>,
    responses: WritableSignal<Message[]>,
    statusMessage: WritableSignal<string | null>,
    conversationId: string
  ): void {
    if (!!this._progressMessageInProgress[conversationId]) return;
    this._progressMessageInProgress[conversationId] = progressMessage;
    this._loadingInProgress[conversationId] = loading;
    this._responses[conversationId] = responses;
    this._uploadFileLoading[conversationId] = uploadFileLoading;
    this._statusMessage[conversationId] = statusMessage;
  }

  public isDocumentTypesValid(files: FileList): boolean {
    const acceptedDocumentTypes: string[] = Object.values(DocType);
    return (
      Array.from(files).filter((file) =>
        acceptedDocumentTypes.includes(file.type)
      ).length === files.length
    );
  }

  public isFileSizeTooBig(files: FileList): boolean {
    let maxSize: number = environment.maxFileSize * 1024 * 1024;
    return Array.from(files).find((x) => x.size > maxSize) != null;
  }
}
