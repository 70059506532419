import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  sessionStorage: Storage;
  localStorage: Storage;

  /**
   * Build an instance of StorageService
   */
  constructor() {
    this.sessionStorage = window.sessionStorage;
    this.localStorage = window.localStorage;
  }

  /**
   * Sauvegarde l'item dans le session storage
   * @param p_key: string
   * @param p_value: any
   */
  storeInSessionStorage(p_key: string, p_value: any): void {
    if (p_value !== undefined && p_value !== null) {
      this.sessionStorage.setItem(p_key, JSON.stringify(p_value));
    }
  }

  /**
   * Récupére l'item dans le session storage
   * @param p_key: string
   * @returns any
   */
  getFromSessionStorage(p_key: string): any {
    return JSON.parse(sessionStorage.getItem(p_key) || '""');
  }

  /**
   * Supprime la clé et l'item renseignés dans le session storage
   * @param p_key: string
   */
  removeFromSessionStorage(p_key: string): void {
    this.sessionStorage.removeItem(p_key);
  }

  /**
   * Supprime tous les éléments du session storage
   */
  clearSessionStorage(): void {
    this.sessionStorage.clear();
  }

  /**
   * Sauvegarde l'item dans le local storage
   * @param p_key: string
   * @param p_value: any
   */
  storeInLocalStorage(p_key: string, p_value: any): void {
    if (p_value !== undefined && p_value !== null) {
      this.localStorage.setItem(p_key, JSON.stringify(p_value));
    }
  }

  /**
   * Récupére l'item dans le local storage
   * @param key: string
   * @returns any
   */
  getFromLocalStorage(p_key: string): any {
    return JSON.parse(localStorage.getItem(p_key) || '{}');
  }

  /**
   * Supprime la clé et l'item renseignés dans local storage
   * @param p_key: string
   */
  removeFromLocalStorage(p_key: string): void {
    this.localStorage.removeItem(p_key);
  }

  /**
   * Supprime tous les éléments du local storage
   */
  clearLocalStorage(): void {
    this.localStorage.clear();
  }
}