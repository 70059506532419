import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventSource } from 'extended-eventsource';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType } from '@azure/msal-browser';

export interface MessageData<T> {
  conversation: string;
  message: T;
}

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private authentService = inject(MsalService);

  private events: Subject<MessageData<any>> = new Subject<MessageData<any>>();
  private eventSource: EventSource | undefined;
  private accessToken: string | undefined;
  private reconnectInterval = 5000; // Reconnect after 5 seconds

  constructor() {
    this.authentService.instance.addEventCallback((evt) => {
      if (
        evt.eventType === EventType.LOGIN_SUCCESS ||
        evt.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        const payload = evt.payload as AuthenticationResult;

        // Set the active account after login or token acquisition
        this.accessToken = payload.accessToken;

        if (
          this.eventSource === undefined ||
          this.eventSource?.readyState === this.eventSource?.CLOSED
        ) {
          this.initializeEventSource(this.accessToken);
        }
      }
    });
  }

  private initializeEventSource(accessToken: string) {
    this.eventSource = new EventSource(
      `${environment.apiConfig.uri}/chat/sse`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    this.eventSource.onmessage = (event) => {
      if (event.data !== 'heartbeat') {
        const message = JSON.parse(event.data);
        const messageData: MessageData<any> = {
          conversation: message.conversationId,
          message,
        };
        this.events.next(messageData);
      }
    };

    this.eventSource.onerror = () => {
      this.eventSource?.close();
      this.eventSource = undefined;

      // Attempt to reconnect after a delay
      setTimeout(() => {
        if(this.accessToken){
          this.initializeEventSource(this.accessToken);
        }
      }, this.reconnectInterval);
    };
  }

  getSSE<T>(): Observable<MessageData<T>> {
    return this.events.asObservable();
  }
}
