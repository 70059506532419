import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from 'app/chat/services/user.service';
import { catchError, first, map, of } from 'rxjs';

export const adminGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.isAdmin$.pipe(
    first(),
    map((isAdmin) => {
      if (isAdmin) return true;
      router.navigate(['/chat']);
      return false;
    }),
    catchError(() => {
      router.navigate(['/chat']);
      return of(false);
    })
  );
};
