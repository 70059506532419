import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { adminGuard } from './admin/admin.guard';

export const routes: Routes = [
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.routes'),
    canActivate: [MsalGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes'),
    canActivate: [MsalGuard, adminGuard],
  },
  {
    path: 'error',
    loadComponent: () => import('./core/components/error/error.component'),
  },
  {
    path: '**',
    redirectTo: 'chat',
    pathMatch: 'full',
  },
];
