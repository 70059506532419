import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StorageService } from 'app/shared/services/storage.service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { ToastService } from './toast.service';
import { Toast } from '../../shared/models/Toast';
import { Habilitations } from '../../shared/models/Habilitations';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private storageService = inject(StorageService);
  private toastService = inject(ToastService);

  private _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isLoaded$: Observable<boolean> = this._isLoaded$.asObservable();

  private _hasNoHabilitations: boolean = false;
  public hasNoHabilitations$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this._hasNoHabilitations);

  private _habilitations$: BehaviorSubject<Habilitations> =
    new BehaviorSubject<Habilitations>({
      models: [],
      tools: [],
      contexts: [],
      roles: [],
    });

  public habilitations$: Observable<Habilitations> =
    this._habilitations$.asObservable();

  public isAdmin$: Observable<boolean> = this.habilitations$.pipe(
    map((habilitations) => habilitations.roles.includes('Roles.Admin')),
    catchError(() => of(false))
  );

  get hasNoHabilitations(): boolean {
    return this._hasNoHabilitations;
  }

  set hasNoHabilitations(value: boolean) {
    this._hasNoHabilitations = value;
    this.hasNoHabilitations$.next(value);
  }

  public getUserHabilitations(): Observable<Habilitations> {
    return this.http
      .get<Habilitations>(`${environment.apiConfig.uri}/habilitations`, {
        withCredentials: true,
      })
      .pipe(
        tap((habilitations) => {
          this._habilitations$.next(habilitations);
          this.hasNoHabilitations = !habilitations;
        }),
        catchError((error) => {
          console.error('Error fetching user habilitations:', error);
          return throwError(() => error);
        })
      );
  }

  constructor() {
    this.getUserHabilitations().subscribe(
      (habilitations) => {
        if (!habilitations) {
          const toast: Toast = {
            message:
              "Vous n'êtes pas habilité, merci de contacter votre administrateur",
            type: 'error',
          };
          this.toastService.showToast(toast);
          this.storageService.clearSessionStorage();
        } else {
          this.storageService.storeInSessionStorage(
            'models',
            habilitations.models.sort()
          );
          this.storageService.storeInSessionStorage(
            'tools',
            habilitations.tools.sort()
          );
          this.storageService.storeInSessionStorage(
            'contexts',
            habilitations.contexts.sort()
          );
        }
      },
      (error) => {
        const toast: Toast = {
          message:
            "Une erreur s'est produite lors de la récupération des habilitations",
          type: 'error',
        };
        this.toastService.showToast(toast);
      },
      () => this._isLoaded$.next(true)
    );
  }
}
