import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  Injectable,
  Injector,
  Signal,
  WritableSignal,
  effect,
  inject,
  runInInjectionContext,
  signal,
} from '@angular/core';
import {
  MonoTypeOperatorFunction,
  Observable,
  catchError,
  map,
  of,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { History } from '../../shared/models/History';
import { Socket } from 'socket.io-client';
import { getSocket } from './socket.service';
import { UserService } from './user.service';
import { Toast } from '../../shared/models/Toast';
import { ToastService } from './toast.service';

const CHAT_ID_KEY = 'PRIVATE_GPT_CHAT_KEY';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private http = inject(HttpClient);
  private _historyList: WritableSignal<History[]> = signal([]);
  private _selectedHistory: WritableSignal<History | undefined> =
    signal(undefined);

  constructor() {
    const chatIdSelected = localStorage.getItem(CHAT_ID_KEY);
    if (chatIdSelected) {
      this.selectHistory({ id: chatIdSelected });
    }
    effect(() => {
      const id = this._selectedHistory()?.id;
      if (id) localStorage.setItem(CHAT_ID_KEY, id);
      else localStorage.removeItem(CHAT_ID_KEY);
    });
    this.getChatHistory();
    // this.refreshSocket();
  }

  // public refreshSocket(): void {
  //   runInInjectionContext(this.injector, () => {
  //     getSocket().then((socket) => {
  //       this.socket = socket;
  //       this.socket.on(
  //         'updated',
  //         ({ conversationId }: { conversationId: string }) => {
  //           timer(300).subscribe(() => {
  //             // Refresh actual messages
  //             if (conversationId === this._selectedHistory()?.id)
  //               this.selectHistory({ id: conversationId });
  //             // Refresh history to get new title if exist
  //             this.getChatHistory();
  //           });
  //         }
  //       );
  //     });
  //   });
  // }

  public get selectedHistory(): Signal<History | undefined> {
    return this._selectedHistory.asReadonly();
  }

  public selectHistory({ id }: { id: string }) {
    this.getChatHistoryByConversationId(id).subscribe();
  }

  private refreshSelectedList(): Observable<Signal<History[]>> {
    return this.http
      .get<History[]>(`${environment.apiConfig.uri}/history`, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          if (!this._selectedHistory()?.id) {
            const lastHistory = res.sort((a, b) => {
              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            })[0];

            if (lastHistory) {
              this.selectHistory({ id: lastHistory.id });
            }
          }

          if (!res.length) {
            this.createNewChat().subscribe();
          }

          this._historyList.set(res);
          return this._historyList.asReadonly();
        })
      );
  }

  public getChatHistory(): Signal<History[] | undefined> {
    this.refreshSelectedList().subscribe();
    return this._historyList;
  }

  public getChatHistoryByConversationId(
    conversationId: string
  ): Observable<History> {
    return this.http
      .get<History>(`${environment.apiConfig.uri}/history/${conversationId}`, {
        withCredentials: true,
      })
      .pipe(tap((res) => this._selectedHistory.set(res)));
  }

  public createChatHistory(title: string): Observable<History> {
    return this.http
      .post<History>(
        `${environment.apiConfig.uri}/history`,
        {
          title,
        },
        { withCredentials: true }
      )
      .pipe(
        this.refreshHistoryList(),
        tap((history) => this.selectHistory(history))
      );
  }

  public createNewChat(): Observable<History> {
    return this.userService.hasNoHabilitations$.pipe(
      switchMap((hasNoHabilitations) => {
        if (hasNoHabilitations) {
          const toast: Toast = {
            message:
              'You are not allowed to use any model, please contact your administrator.',
            type: 'error',
            duration: 10000,
          };
          this.toastService.showToast(toast);
          return throwError(
            () =>
              new Error(
                'You are not allowed to access this application, please contact your administrator.'
              )
          );
        } else {
          // L'utilisateur est habilité, poursuivre la création du chat
          return this.createChatHistory('Conversation sans titre');
        }
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  public deleteChatHistory(id: string): Observable<void> {
    if (this._selectedHistory()?.id === id) {
      this._selectedHistory.set(undefined);
    }
    return this.http
      .delete<void>(`${environment.apiConfig.uri}/history/${id}`, {
        withCredentials: true,
      })
      .pipe(this.refreshHistoryList());
  }

  private refreshHistoryList<T>(): MonoTypeOperatorFunction<T> {
    return tap(() => this.refreshSelectedList().subscribe());
  }

  public updateHistoryTitle(title: string): Observable<History> {
    const id = this._selectedHistory()?.id;
    if (!id) return throwError(() => new Error('No history selected'));
    return this.http
      .put<History>(
        `${environment.apiConfig.uri}/history/${id}`,
        {
          title,
        },
        { withCredentials: true }
      )
      .pipe(this.refreshHistoryList());
  }
}
