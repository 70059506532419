export const environment = {
  production: true,
  msalConfig: {
    auth: {
      clientId: '74ffbf51-13d1-4efc-b545-805bfc37d5b7',
      authority:
        'https://login.microsoftonline.com/f7629bec-23b1-42a3-bbfd-7ac0dd7d7b3c/oauth2/v2.0/authorize',
    },
  },
  apiConfig: {
    scopes: ['api://74ffbf51-13d1-4efc-b545-805bfc37d5b7/User.Read'],
    uri: '//privategpt-recette-back.apside.cloud',
  },
  wsConfig: {
    url: '//privategpt-recette-back.apside.cloud',
  },
  documentEmbeddingLimitPerConversation: 3,
  maxFileSize: 100,
};
